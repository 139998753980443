import React from "react";
import Particle from "./Particle";
import TextEffect from "./TextEffect";

import { ArrowDownTrayIcon } from "@heroicons/react/20/solid";
import TextSphere from "./TextSphere";

const Hero = () => {
  return (
    <div className="h-[88vh] bg-[#0C0017] mt-[12vh] bg-cover bg-center">
      <Particle />
      <div className="w-[80%] grid-cols-1 mx-auto grid lg:grid-cols-2 gap-[3rem] h-[100%] items-center">
        <div>
          <h1 className="text-[30px] md:text-[45px]  text-white font-bold">
            HI, I AM{" "}
            <span
              style={{
                background: "linear-gradient(to top, #D32F2F 0%, #FBC02D 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              EDISON YANG!
            </span>
          </h1>
          <TextEffect />
          <div className="flex-start">
            <p className="mt-[1.5rem] text-[18px] text-[#ffffff92]">
              HEY...HOW U DOIN&apos; ? Welcome to this tiny world of mine!
            </p>
            <p className="text-[18px] text-[#ffffff92] mt-2">
              Please grab a copy of my resume or scroll down to get to know me!
            </p>
          </div>
          <div
            className="mt-[2rem] flex-col space-y-6 sm:space-y-0 sm:flex
                            sm:flex-row items-center sm:space-x-6"
          >
            <a
              href="https://drive.google.com/uc?export=download&id=1GKbg8qRyThXzq9sonYbQ4lwyO_n8-tIl"
              download="Lishun_Yang_CV.pdf"
            >
              <button
                className="px-[2rem] hover:bg-[#8A2BE2] transition-all 
                duration-200 py-[1rem] text-[18px] font-bold uppercase
                bg-[#55e6a5] text-black flex items-center space-x-2"
              >
                Download CV
                <ArrowDownTrayIcon className="w-[1.6rem] h-[1.7rem] ml-2 text-black" />
              </button>
            </a>
          </div>
        </div>
        <div className="w-[500px] hidden bg-[#1e12212] relative items-center rounded-full h-[500px] lg:flex">
          <TextSphere />
        </div>
      </div>
    </div>
  );
};

export default Hero;
