import React from "react";
import ExperienceItem from "./ExperienceItem";
import bnbLogo from "../src/images/bnb.png";
import monashLogo from "../src/images/monash.png";

const experiences = [
  {
    id: 1,
    title: "Junior Software Engineer",
    company: "Monash eSolutions",
    date: "Sep 2024 - Present",
    logo: monashLogo,
    description: [
      "Contributed to the development of a private AI-powered web application hosted on on-premises virtual machines.",
      "Collaborated with other engineers on AWS and Azure infrastructures, ensuring seamless integration and leveraging Azure services for AI functionality.",
      "Worked closely with cross-functional teams (e.g., cyber, database team etc.) to meet project requirements.",
    ],
  },
  {
    id: 2,
    title: "Martian",
    company: "BNB Chain Innovation",
    date: "May 2024 - Present",
    logo: bnbLogo,
    description: [
      "Develop and maintain bot servers to help engage with TG community group users",
      "Help host weekly AMA session in TG community group",
    ],
  },
];

const Experiences = ({ id }) => {
  return (
    <div id={id} className="bg-[#121212] py-[5rem] h-auto">
      <div className="w-[80%] mx-auto text-center">
        <h1 className="heading">
          Expe<span className="text-[#fb923c]">rience</span>
        </h1>
        <h1 className="text-[20px] font-bold uppercase text-[#55e6a5] mb-2">
          My Work Journey
        </h1>
      </div>

      <div className="relative w-[80%] mx-auto flex flex-col items-center">
        {/* Vertical Line */}
        <div className="absolute top-0 left-1/2 w-[5px] bg-[#55e6a5] h-full transform -translate-x-1/2" />
        {experiences.map((exp, index) => (
          <ExperienceItem key={exp.id} experience={exp} index={index} />
        ))}
      </div>
    </div>
  );
};

export default Experiences;
