import React from "react";

const ExperienceItem = ({ experience, index }) => {
  return (
    <div
      data-aos="zoom-in"
      data-aos-delay="200"
      className={`relative flex items-center w-full my-[2rem] ${
        index % 2 === 0 ? "justify-start" : "justify-end"
      }`}
    >
      {/* Dot in the Timeline */}
      <div className="absolute left-1/2 transform -translate-x-1/2 w-[20px] h-[20px] bg-[#fb923c] rounded-full border-4 border-[#121212]" />

      {/* Experience Box */}
      <div
        className={`bg-[#1e1e1e] p-6 rounded-lg shadow-lg w-[45%] text-white ${
          index % 2 === 0 ? "mr-auto" : "ml-auto"
        }`}
      >
        <div className="flex items-center mb-4">
          <img
            src={experience.logo}
            alt={experience.company}
            className="w-16 h-16 object-contain mr-4"
          />
          <h3 className="text-[22px] font-bold text-[#55e6a5]">
            {experience.title}
          </h3>
        </div>
        <p className="text-[18px] text-[#fb923c] font-semibold">
          {experience.company}
        </p>
        <p className="text-[16px] text-gray-300 italic">{experience.date}</p>
        <ul className="mt-2 text-gray-400 list-disc pl-5">
          {experience.description.map((point, idx) => (
            <li key={idx}>{point}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ExperienceItem;
